import classNames from 'classnames'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import ChevronIcon from '../assets/icons/chevron-small.svg'
import { WindowContext } from '../context/WindowContext'
import { useOnClickOutside } from '../hooks/useOnClickOutside'
import { isInternalLink } from '../utilities/string'
import { Link } from './Link'

export interface LinkDropdown {
  id: string
  label: string
  url: string
}

export enum LinksDropdownVariant {
  Default,
  Compact,
  Inline,
}

export interface LinksDropdownProps {
  activeItemId?: string
  additionalClassName?: string
  isFixed?: boolean
  items: LinkDropdown[]
  placeholderLabel?: string
  variant?: LinksDropdownVariant
}

export const LinksDropdown = ({
  activeItemId,
  additionalClassName,
  isFixed,
  items,
  placeholderLabel,
  variant = LinksDropdownVariant.Default,
}: LinksDropdownProps) => {
  const [open, setOpen] = useState(false)
  const { bodyElement } = useContext(WindowContext)
  const containerRef = useRef<HTMLDivElement>(null)
  const flyoutRef = useRef<HTMLDivElement>(null)

  // Need to pass in both container and flyout refs here, since the flyout is rendered
  // using a React Portal.
  const clickOutsideRefs = useMemo(() => [containerRef, flyoutRef], [])
  useOnClickOutside(clickOutsideRefs, () => setOpen(false))

  useEffect(() => {
    const onResize = () => {
      if (bodyElement && containerRef.current && flyoutRef.current) {
        const rect = containerRef.current.getBoundingClientRect()

        let left = rect.left
        let top = rect.bottom

        if (!isFixed) {
          const bodyRect = bodyElement.getBoundingClientRect()
          top -= bodyRect.top
        }

        // Constrain to window bounds
        if (left < 0) {
          left = 0
        } else if (left + flyoutRef.current.clientWidth > window.innerWidth) {
          left = window.innerWidth - flyoutRef.current.clientWidth
        }

        if (top < 0) {
          top = 0
        } else if (top + flyoutRef.current.clientHeight > window.innerHeight) {
          top = window.innerHeight - flyoutRef.current.clientHeight
        }

        flyoutRef.current.style.left = `${left}px`
        flyoutRef.current.style.top = `${top}px`
        flyoutRef.current.style.minWidth = `${containerRef.current.clientWidth}px`
      }
    }

    window.addEventListener('resize', onResize)

    onResize()

    return () => window.removeEventListener('resize', onResize)
  }, [bodyElement, isFixed, open])

  return (
    <div className={classNames('flex relative', additionalClassName)} ref={containerRef}>
      <button
        className={classNames(
          'flex w-full justify-between items-center gap-7 group transition-all duration-200',
          'rounded-15',
          'outline-none focus:outline-none focus:ring focus:ring-primary',
          {
            [classNames(
              'bg-neutral border border-neutral-light',
              'hover:border-primary'
            )]: variant !== LinksDropdownVariant.Inline,

            'px-10 py-5': variant === LinksDropdownVariant.Inline,
            'px-30 py-15': variant === LinksDropdownVariant.Default,
            'px-[0.75rem] py-[0.5625rem]': variant === LinksDropdownVariant.Compact,
          }
        )}
        onClick={() => setOpen(old => !old)}
      >
        <span
          className={classNames(
            'text-secondary-darker font-t-bold transition-all duration-200',
            'group-hover:text-primary group-focus:text-primary',
            {
              'text-t-200':
                variant === LinksDropdownVariant.Compact ||
                variant === LinksDropdownVariant.Inline,
              'text-t-300': variant === LinksDropdownVariant.Default,
            }
          )}
        >
          {placeholderLabel}
        </span>

        <ChevronIcon
          className={classNames(
            'w-[10px] h-[10px] fill-current transition-all duration-200 group-hover:text-primary group-focus:text-primary',
            {
              '-rotate-90': !open,
              'rotate-90': open,
            }
          )}
        />
      </button>

      {/* Use a portal to render the flyout to avoid z-index issues */}
      {bodyElement
        ? createPortal(
            <div
              className={classNames(
                'z-highest bg-neutral rounded-4 px-15 py-8 shadow-soft-sm transition-[opacity,transform] duration-200',
                {
                  'absolute': !isFixed,
                  'fixed': isFixed,
                  'translate-y-[1px] pointer-events-auto': open,
                  'opacity-0 pointer-events-none': !open,
                  '-translate-y-5': !open && variant !== LinksDropdownVariant.Inline,
                  '-translate-y-2': !open && variant === LinksDropdownVariant.Inline,
                }
              )}
              ref={flyoutRef}
            >
              <ul className="flex flex-col gap-1 font-t text-t-200">
                {items.map(item => (
                  <li key={item.id}>
                    {item.id === activeItemId ? (
                      <span className="block px-4 py-7 text-neutral-dark">
                        {item.label}
                      </span>
                    ) : (
                      <Link
                        className={classNames(
                          'flex items-center flex-none px-4 py-7 text-secondary-darkest transition-all duration-200 hover:text-primary',
                          'outline-none focus:outline-none focus:text-primary'
                        )}
                        isExternal={isInternalLink(item.url) === false}
                        tabIndex={open ? undefined : -1}
                        url={item.url}
                      >
                        <span className="whitespace-nowrap">{item.label}</span>

                        <ChevronIcon
                          className={classNames(
                            'w-[8px] h-[8px] flex-none ml-8 fill-current rotate-180'
                          )}
                        />
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>,
            // Fixed elements can't be put inside a perspective wrapper, so make sure that it is always
            // put into the document body if the current page is a parallax page.
            isFixed ? document.body : bodyElement
          )
        : null}
    </div>
  )
}
