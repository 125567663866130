export const IS_EQT_GROUP =
  !process.env.GATSBY_SITE || process.env.GATSBY_SITE === 'eqt-web'

export const IS_EQT_FOUNDATION = process.env.GATSBY_SITE === 'eqt-foundation'

export const IS_EQT_MARKET = process.env.GATSBY_SITE === 'eqt-market'

export const IS_EQT_FUNDS = process.env.GATSBY_SITE === 'eqt-funds'

export const IS_GATSBY_PREVIEW = process.env.GATSBY_BASE_URL?.includes('preview') === true

export const GROUP_SITE_URL = IS_GATSBY_PREVIEW
  ? 'https://preview-eqtwebmain.gatsbyjs.io'
  : 'https://eqtgroup.com'

export const MARKET_SITE_LINKS =
  IS_EQT_GROUP || IS_EQT_MARKET
    ? [
        {
          id: 'china',
          label: 'China',
          url: IS_GATSBY_PREVIEW
            ? 'https://preview-eqtchina.gatsbyjs.io'
            : 'https://chn.eqtgroup.com',
        },
        {
          id: 'korea',
          label: 'South Korea',
          url: IS_GATSBY_PREVIEW
            ? 'https://preview-eqtkorea.gatsbyjs.io'
            : 'https://kor.eqtgroup.com',
        },
        {
          id: 'japan',
          label: 'Japan',
          url: IS_GATSBY_PREVIEW
            ? 'https://preview-eqtjapan.gatsbyjs.io'
            : 'https://jpn.eqtgroup.com',
        },
        {
          id: 'sweden',
          label: 'Sweden',
          url: IS_GATSBY_PREVIEW
            ? 'https://preview-eqtsweden.gatsbyjs.io'
            : 'https://swe.eqtgroup.com',
        },
      ].sort((left, right) => {
        // Sort the links so that the current site is first.
        if (left.url === right.url) {
          return 0
        } else if (left.url === process.env.GATSBY_BASE_URL) {
          return -1
        } else if (right.url === process.env.GATSBY_BASE_URL) {
          return 1
        } else {
          return left.label < right.label ? -1 : 1
        }
      })
    : []
