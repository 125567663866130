import { WrapPageElementBrowserArgs, WrapRootElementBrowserArgs } from 'gatsby'
import React from 'react'
import { HeaderContextProvider } from './src/context/HeaderContext'
import { LocalizationContextProvider } from './src/context/LocalizationContext'
import { PageContextProvider } from './src/context/PageContext'
import { SiteWideDisclaimerContextProvider } from './src/context/SiteWideDisclaimerContext'
import { WindowContextProvider } from './src/context/WindowContext'
import './src/styles/index.css'
import { SitePageContext } from './types/generated/graphql-types'

export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementBrowserArgs<unknown, SitePageContext>) => (
  <LocalizationContextProvider currentLanguage={props.pageContext?.language ?? 'en'}>
    <PageContextProvider pageContext={props.pageContext} path={props.path}>
      <WindowContextProvider>
        <SiteWideDisclaimerContextProvider>{element}</SiteWideDisclaimerContextProvider>
      </WindowContextProvider>
    </PageContextProvider>
  </LocalizationContextProvider>
)

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => (
  <HeaderContextProvider>{element}</HeaderContextProvider>
)

declare global {
  interface Window {
    locations?: Array<string>
    previousPath?: string
  }
}

export const onRouteUpdate = (): void => {
  window.locations = window.locations || [document.referrer]
  window.locations.push(window.location.href)
  window.previousPath = window.locations[window.locations.length - 2]
}
