import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import Cross from '../../assets/icons/cross.svg'
import MagnifyingGlass from '../../assets/icons/magnifying-glass.svg'
import { useTranslations } from '../../hooks/useTranslations'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'
import { IS_EQT_FOUNDATION } from '../../utilities/site'
import { isNullOrWhiteSpace } from '../../utilities/string'
import { Transition } from '../Transition'

export interface SearchBarProps {
  onClose: () => void
  open: boolean
  searchResultsPageUrl: string
}

export const SearchBar = ({
  onClose,
  open,
  searchResultsPageUrl,
}: SearchBarProps): JSX.Element => {
  const [value, setValue] = useState<string>('')
  const translations = useTranslations()
  const inputRef = useRef<HTMLInputElement>(null)

  // Use documentClientWidth here to NOT overlap the browsers scrollbar on parallax pages
  const { documentClientWidth } = useWindowDimensions()

  const onClickMagnifyingGlass: React.MouseEventHandler<HTMLButtonElement> = e => {
    if (isNullOrWhiteSpace(value)) {
      if (inputRef.current) {
        inputRef.current.focus()
        e.preventDefault()
      }
    }
  }

  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    document.addEventListener('keyup', handleKey)

    return () => {
      document.removeEventListener('keyup', handleKey)
    }
  }, [onClose])

  return (
    <>
      {/* Apply a full-screen overlay effect while the search bar is open */}
      <Transition
        className="fixed inset-0 bg-neutral-dark z-navbar-search-bar transition-opacity blend-multiply"
        duration={150}
        enterTo="opacity-100"
        leaveTo="opacity-0 pointer-events-none"
        onClick={onClose}
        show={open}
        style={{
          width: `${documentClientWidth}px`,
        }}
      />

      <Transition
        className="fixed top-0 left-0 h-90 w-full z-navbar-search-bar bg-secondary-darkest transform duration-200"
        duration={200}
        enterTo="translate-y-0"
        leaveTo="-translate-y-full"
        onEntered={() => {
          if (inputRef.current) {
            inputRef.current.focus()
          }
        }}
        show={open}
        style={{
          width: `${documentClientWidth}px`,
        }}
      >
        <form
          className="flex w-full h-full justify-center items-center px-30 sm:px-45"
          action={searchResultsPageUrl}
          method="get"
        >
          {/* Counter-weight div to keep the search bar div centered */}
          <div className="flex-1"></div>
          <div className="flex flex-auto max-w-[37.5rem] w-full items-center border border-neutral-lighter rounded">
            <div className="flex items-center w-full px-10 py-7">
              <input
                aria-label="Search term"
                className={classNames(
                  'flex-1 min-w-0 bg-transparent outline-none font-t-light text-t-400',
                  IS_EQT_FOUNDATION
                    ? 'text-neutral placeholder-neutral-lighter placeholder-opacity-100'
                    : 'text-neutral-lighter'
                )}
                name="q"
                onChange={e => setValue(e.target.value)}
                placeholder={translations.searchInputPlaceholder ?? undefined}
                ref={inputRef}
                required
                tabIndex={1}
                type="text"
                value={value}
              />

              <button
                className={classNames(
                  'inline-flex px-10 py-9 items-center border rounded-8 bg-neutral text-secondary-darkest font-button text-button-md uppercase',
                  'transition-hover group',
                  'hover:text-primary',
                  'focus:ring focus:ring-neutral focus:outline-none'
                )}
                onClick={onClickMagnifyingGlass}
                tabIndex={1}
              >
                <MagnifyingGlass className="w-10 h-10 mr-4 fill-current group-hover:text-primary transition-hover group-hover:duration-250" />
                {translations.searchBarButtonLabel}
              </button>
            </div>
          </div>
          <div className="flex flex-1 justify-end">
            <button
              className="
                flex items-center justify-center ml-15 p-15 transition-all rounded bg-neutral bg-opacity-0 
                hover:bg-opacity-20
                focus:ring focus:ring-neutral focus:outline-none
              "
              onClick={e => {
                onClose()
                e.preventDefault()
              }}
              tabIndex={1}
            >
              <Cross className="w-15 h-15 fill-current text-neutral" />
            </button>
          </div>
        </form>
      </Transition>
    </>
  )
}
