import classNames from 'classnames'
import React, {
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { HeaderAPIContext, HeaderDataContext } from '../../context/HeaderContext'
import { useBreakpoint } from '../../hooks/useBreakpoint'
import { usePrevious } from '../../hooks/usePrevious'
import { useTimer } from '../../hooks/useTimer'
import { useWindowScroll } from '../../hooks/useWindowScroll'
import { InternalExternalLink } from '../../types/InternalExternalLink'
import { MainNavigationNode } from '../../types/MainNavigationNode'
import { Breakpoints } from '../../utilities/breakpoints'
import { IS_EQT_FOUNDATION, IS_EQT_MARKET } from '../../utilities/site'
import { isNullOrWhiteSpace } from '../../utilities/string'
import { Grid } from '../Grid'
import { ExternalIconSize, Link } from '../Link'
import { Logo } from '../Logo'
import { NavigationFlyout } from './NavigationFlyout'
import { NavigationOpenerButtons } from './NavigationOpenerButtons'
import { NavigationPillButton } from './NavigationPillButton'
import { ScrollProgressBar } from './ScrollProgressBar'
import { SearchBar } from './SearchBar'
import { SecondaryNavigationNode } from './SecondaryNavigationNode'
import { shouldRenderSitePicker, TopNavBar, TopNavBarProps } from './TopNavBar'
import Contactbutton from './Contactbutton'
import logo from '../../assets/brand/eqt-eqrt-large.png'


export interface HeaderProps {
  currentPageUrl: string

  homePageUrl: string

  mainNavigationNodes: MainNavigationNode[]

  pillLink1?: InternalExternalLink

  pillLink2?: InternalExternalLink

  searchResultsPageUrl?: string

  secondaryNavigationNodes: SecondaryNavigationNode[]

  setFixedElements?: (elements: ReactNode) => void

  topNavBarProps?: TopNavBarProps
}

export const BASE_HEADER_HEIGHT = 90

const SEARCH_BAR_HEIGHT = 90

export const Header = ({
  currentPageUrl,

  homePageUrl,

  mainNavigationNodes,

  pillLink1,

  pillLink2,

  searchResultsPageUrl,

  secondaryNavigationNodes,

  setFixedElements,

  topNavBarProps,
}: HeaderProps): JSX.Element => {
  const scrollYPosition = useWindowScroll()

  const previousYPosition = usePrevious(scrollYPosition) ?? 0

  const scrollStartPosition = useRef(0)

  const previousScrollDirection = useRef<'up' | 'down'>('up')

  const inputRef = useRef<HTMLInputElement>(null)

  const [aboveMobile, setAboveMobile] = useState(false)

  const [animationsEnabled, setAnimationsEnabled] = useState(false)

  const [headerTop, setHeaderTop] = useState(0)

  const [navigationFlyoutOpen, setNavigationFlyoutOpen] = useState(false)

  const [searchBarOpen, setSearchBarOpen] = useState(false)

  const [topNavBarHeight, setTopNavBarHeight] = useState(50)

  const [topNavBarVisible, setTopNavBarVisible] = useState(false)

  const [headerHeight, setHeaderHeight] = [
    useContext(HeaderDataContext),

    useContext(HeaderAPIContext),
  ]

  const animationClasses = animationsEnabled ? 'transition-all' : 'transition-inset'

  const maxMainNavigationItems = 6

  const animationTimer = useTimer()

  useBreakpoint(Breakpoints.tb, setAboveMobile)

  const renderTopNavBar = !!topNavBarProps

  const hasSitePicker =
    topNavBarProps?.sitePickerProps &&
    shouldRenderSitePicker(topNavBarProps.sitePickerProps)

  const hasLanguageOptions =
    !!topNavBarProps?.languageSelectProps?.languageVersions?.length

  const renderHamburgerButtonAboveMd =
    !!secondaryNavigationNodes.length ||
    mainNavigationNodes.some(node => !!node.subitems.length)

  // Keep animations disabled on page load until we scroll for the first time, to avoid jumpyness

  useEffect(() => {
    if (scrollYPosition > 0) {
      setAnimationsEnabled(true)
    }
  }, [scrollYPosition])

  // Track the scroll direction to show/hide the top nav bar

  useLayoutEffect(() => {
    if (renderTopNavBar && navigationFlyoutOpen === false) {
      // Always show it when at the top of the page

      if (scrollYPosition === 0) {
        setTopNavBarVisible(true)

        animationTimer.start(null, 300)
      } else if (animationTimer.isRunning() === false) {
        const scrollDirection = scrollYPosition > previousYPosition ? 'down' : 'up'

        if (scrollDirection !== previousScrollDirection.current) {
          scrollStartPosition.current = scrollYPosition
        } else {
          // Immediately hide the top nav bar if scrolling down, but keep it visible a certain amount of time

          // if scrolling up.

          if (scrollDirection === 'down' && topNavBarVisible) {
            setTopNavBarVisible(false)

            animationTimer.start(null, 250)
          } else if (
            scrollDirection === 'up' &&
            !topNavBarVisible &&
            Math.abs(scrollYPosition - scrollStartPosition.current) > 200
          ) {
            setTopNavBarVisible(true)

            animationTimer.start(null, 250)
          }
        }

        previousScrollDirection.current = scrollDirection
      }
    }
  }, [
    animationTimer,

    navigationFlyoutOpen,

    previousYPosition,

    renderTopNavBar,

    scrollYPosition,

    topNavBarVisible,
  ])

  // Close the top nav bar when opening the navigation flyout

  useEffect(() => {
    if (navigationFlyoutOpen) {
      setTopNavBarVisible(false)
    }
  }, [navigationFlyoutOpen])

  // Close navigation flyout using the enter key

  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      if (searchBarOpen === false && e.key === 'Escape') {
        setNavigationFlyoutOpen(false)

        if (inputRef.current) {
          inputRef.current.blur()
        }
      }
    }

    document.addEventListener('keyup', handleKey)

    return () => {
      document.removeEventListener('keyup', handleKey)
    }
  }, [searchBarOpen])

  // Handle top nav bar height

  useLayoutEffect(() => {
    if (aboveMobile === false && hasSitePicker && hasLanguageOptions) {
      setTopNavBarHeight(90)
    } else {
      setTopNavBarHeight(50)
    }
  }, [aboveMobile, hasLanguageOptions, hasSitePicker])

  // Handle header height and top values

  useLayoutEffect(() => {
    let height = BASE_HEADER_HEIGHT

    let top = 0

    if (renderTopNavBar) {
      top -= topNavBarHeight

      if (topNavBarVisible) {
        height += topNavBarHeight

        top += topNavBarHeight
      }
    }

    if (searchBarOpen) {
      height += SEARCH_BAR_HEIGHT

      top += SEARCH_BAR_HEIGHT
    }

    setHeaderHeight(height)

    setHeaderTop(top)
  }, [searchBarOpen, setHeaderHeight, topNavBarVisible, topNavBarHeight, renderTopNavBar])

  // Render fixed elements with specific z-indices

  useLayoutEffect(() => {
    if (!IS_EQT_MARKET) {
      const onSearchClick = () => {
        setSearchBarOpen(true)
      }

      const closeMenuOnFailedNavigation = (url: string) => {
        // note: filters (e.g. /people/specialist-functions) are currently not included in currentPageUrl

        if (isNullOrWhiteSpace(url) || url === currentPageUrl) {
          setNavigationFlyoutOpen(false)
        }
      }

      let navigationOpenerButtonsTop = headerTop

      if (renderTopNavBar) {
        navigationOpenerButtonsTop = headerTop + topNavBarHeight
      }

      // These elements must be fixed - and fixed elements are required to be put outside any css perspective wrappers,

      // otherwise parallax effects stop working (at least in Firefox).

      setFixedElements?.(
        <>
          {/* The buttons are rendered outside the sticky navbar as a fixed element with a high z-index. Otherwise they would

            get covered by the NavigationFlyout. */}

          <NavigationOpenerButtons
            active={navigationFlyoutOpen}
            onMenuClick={() => setNavigationFlyoutOpen(previous => !previous)}
            onSearchClick={onSearchClick}
            renderSearchButton={!isNullOrWhiteSpace(searchResultsPageUrl)}
            renderNavigationOpenerButtonAboveMd={renderHamburgerButtonAboveMd}
            top={navigationOpenerButtonsTop}
          />

          <NavigationFlyout
            currentPageUrl={currentPageUrl}
            mainNavigationNodes={mainNavigationNodes}
            onNavigate={closeMenuOnFailedNavigation}
            open={navigationFlyoutOpen}
            pillLink1={pillLink1}
            pillLink2={pillLink2}
            searchBarOpen={searchBarOpen}
            secondaryNavigationNodes={secondaryNavigationNodes}
          />

          <SearchBar
            open={searchBarOpen}
            onClose={() => {
              setSearchBarOpen(false)
            }}
            searchResultsPageUrl={searchResultsPageUrl ?? ''}
          />
        </>
      )
    }
  }, [
    currentPageUrl,

    headerTop,

    inputRef,

    mainNavigationNodes,

    navigationFlyoutOpen,

    pillLink1,

    pillLink2,

    renderHamburgerButtonAboveMd,

    renderTopNavBar,

    searchBarOpen,

    searchResultsPageUrl,

    secondaryNavigationNodes,

    setFixedElements,

    topNavBarHeight,
  ])

  const pillButtonsVisible =
    (!isNullOrWhiteSpace(pillLink1?.title) && !isNullOrWhiteSpace(pillLink1?.url)) ||
    (!isNullOrWhiteSpace(pillLink2?.title) && !isNullOrWhiteSpace(pillLink2?.url))

  return (
    <>
      <nav
        className={classNames(
          'sticky bg-site-background z-navbar duration-200 header-fixes-on-respo',

          animationClasses
        )}
        style={{
          top: `${headerTop}px`,
        }}
      >
        {renderTopNavBar && <TopNavBar {...topNavBarProps} />}

        {/* new div */}

        <div className="py-15 flex justify-end bg-[#FAF8F6] bg-no-repeat bg-cover  opacity-100 pr-30 mobile-none">
          {pillButtonsVisible && (
            <div

              className={classNames(
                'hidden',

                'sm:col-start-2 sm:col-span-2 sm:flex sm:items-start sm:space-x-15 changes-top-hed-chngs',

                'md:col-start-1 md:col-span-2',

                'lg:col-start-2 lg:col-span-2',


                'xxl:col-start-3 xxl:col-span-2'
              )}
            >
              {pillLink1 &&
                !isNullOrWhiteSpace(pillLink1.title) &&
                !isNullOrWhiteSpace(pillLink1.url) && (
                  <NavigationPillButton
                    currentPageUrl={currentPageUrl}
                    link={pillLink1}
                    tabIndex={1}
                  >
                    {pillLink1.title}
                  </NavigationPillButton>
                )}

              {pillLink2 &&
                !isNullOrWhiteSpace(pillLink2.title) &&
                !isNullOrWhiteSpace(pillLink2.url) && (
                  <NavigationPillButton
                    currentPageUrl={currentPageUrl}
                    link={pillLink2}
                    tabIndex={1}
                  >
                    {pillLink2.title}
                  </NavigationPillButton>
                )}

              <button className="ml-75 text-center font-normal text-base leading-5 font-sofia-pro tracking-normal text-gray-700 opacity-100 inline-flex items-center rounded  uppercase text-secondary-darker focus:text-neutral focus:outline-none ">
                <Contactbutton />
              </button>
            </div>

          )}
        </div>

        <Grid
          additionalClassName="relative h-90 tb:pt-30"
          contentAlignment="flex justify-between items-center"
        >
          <div className="col-start-1 col-span-1 h-90 flex items-center  mb-10">
            <Link
              className="inline-block relative fill-current text-primary"
              url={homePageUrl}
              tabIndex={2}
            >
              <div
                style={{
                  marginBottom: '60px',
                  marginLeft:'20px',
                  width: '238px',
                  height: '74px',
                  opacity: 1,
                }}
              >
                <img src={logo} alt="logo" className='logo-mobile-respon'/>
                {/* <Logo /> */}
              </div>
            </Link>
          </div>


          <ul
            className={classNames(
              'hidden',

              'md:flex md:items-start md:col-start-4 md:col-span-3  ',

              IS_EQT_FOUNDATION ? 'md:ml-90' : 'md:ml-60',

              'lg:col-start-5 lg:col-span-3 lg:ml-[6.5625rem] lg:-mr-60 ',

              'xl:col-start-7 xl:col-span-4 xl:ml-0 xl:-mr-90',

              'xxl:col-start-10 xxl:col-span-3 xxl:-ml-30 xxl:-mr-45',
              'justify-between : justify-around',

            )}
          >
            {mainNavigationNodes.slice(0, maxMainNavigationItems).map((node, index) => (
              <li className="leading-[1.25rem] " key={index}>

                <Link

                  {...node.link}
                  className={classNames(
                    'flex items-center group relative font-navbar-link text-navbar-link  mr-30 mb-45 text-secondary-darker transition-hover ',

                    'hover:text-primary focus:outline-none',

                  )}
                  externalIconSize={ExternalIconSize.Md}
                  hideContentIfNoAccess
                  tabIndex={3}
                  url={node.link.url ?? ''}
                >
                  {node.link.title}

                  <span
                    className={`absolute bootom-top-dsgn left-0 block w-full h-2 mt-2 transition-hover ${currentPageUrl === node.link.url
                      ? 'bg-quinary'
                      : 'bg-transparent group-hover:bg-neutral-lighter-alt group-focus:bg-primary-alpha-25'
                      }`}
                    aria-hidden="true"
                  />
                </Link>
              </li>
            ))}
          </ul>

        </Grid>






      </nav>

      {/* Main menu border is separated into its own div with a different z-index. This is done so that we can overlay

          sticky menu's on top of the border, thereby hiding the border and "merging" the sticky menu into the main menu. */}

      <div
        className={classNames(
          'sticky z-navbar-scroll-progress-bar border-b-2 border-neutral-lighter duration-200',

          animationClasses
        )}
        style={{
          top: `${headerHeight}px`,
        }}
      >
        <ScrollProgressBar />
      </div>
    </>
  )
}
