import classNames from 'classnames'
import React, { FC, PropsWithChildren } from 'react'

export interface IconButtonProps extends PropsWithChildren<unknown> {
  /**
   * Imported SVG
   * @example
   * // Import SVG (by convention from svg-react-loader)
   * import Logo from './logo.svg'
   *
   * // Pass "Logo" as prop
   * return <IconButton svg={Logo} />
   */
  svg: (props: React.SVGProps<HTMLOrSVGElement>) => JSX.Element

  /**
   * Aria-label, used to provide a proper description for screen readers.
   * NOTE: if no click handler is specified, make sure there is a label
   * on the wrapping handler element, if any
   */
  label?: string

  /** Button type */
  type?: 'button' | 'submit'

  /**
   * Pass true to render a smaller version of the button (25px)
   */
  small?: boolean

  /**
   * Pass true to default to transparent background
   */
  transparent?: boolean

  /**
   * Event handler triggered on click,
   * leave empty if a parent component should be the event target
   */
  handleClick?: () => void

  /** Additional class names to apply to the button */
  additionalClassName?: string

  tabIndex?: number

  /** Indicates "active" state, i.e. button is toggled on */
  active?: boolean

  disabled?: boolean
}

export const IconButton: FC<IconButtonProps> = ({
  active,
  additionalClassName,
  children,
  disabled,
  handleClick,
  label,
  small,
  svg: Svg, // Variables used as components must start with a capital letter
  tabIndex,
  transparent,
  type = 'button',
}) => {
  const hasChildren = children && React.Children.count(children) > 0

  let background = transparent ? 'bg-transparent' : 'bg-neutral'
  let borderColor = 'border-neutral-lighter-alt'
  let borderRadius: string
  let cursor = ''
  let focus = ''
  let height = ''
  let hover = ''
  let padding: string
  let textColor = 'text-secondary-darker'
  let width = ''

  if (hasChildren) {
    borderRadius = 'rounded-5'
    padding = 'px-15 py-10'
  } else {
    borderRadius = small ? 'rounded-5' : 'rounded'
    height = small ? 'h-30' : 'h-45'
    padding = 'p-6'
    width = small ? 'w-30' : 'w-45'
  }

  if (disabled) {
    cursor = 'cursor-not-allowed'
    textColor = 'text-neutral-medium'
  } else {
    focus = classNames('focus:ring group-focus:ring focus:outline-none', {
      'focus:ring-neutral group-focus:ring-neutral': hasChildren,
      'focus:text-primary group-focus:text-primary focus:border-primary group-focus:border-primary focus:ring-primary group-focus:ring-primary':
        !hasChildren,
    })

    if (active) {
      background = 'bg-primary'
      borderColor = 'border-primary'
      textColor = 'text-neutral'
    } else {
      hover = classNames('hover:text-primary group-hover:text-primary', {
        'hover:border-primary': !hasChildren,
      })
    }
  }

  const className = classNames(
    'inline-flex flex-none justify-center items-center uppercase group transition-hover',
    'border font-button text-button-md',
    width,
    height,
    padding,
    background,
    borderColor,
    borderRadius,
    textColor,
    hover,
    focus,
    cursor,
    additionalClassName
  )

  const content = (
    <>
      <Svg
        className={classNames(
          'fill-current transition-hover',
          hasChildren ? 'mr-10' : '',
          textColor,
          hover
        )}
      />
      {children}
    </>
  )

  return handleClick ? (
    <button
      aria-label={label}
      className={className}
      disabled={disabled}
      onClick={handleClick}
      tabIndex={tabIndex}
      type={type}
    >
      {content}
    </button>
  ) : (
    <div className={className}>{content}</div>
  )
}
