exports.components = {
  "component---src-pages-404-not-found-tsx": () => import("./../../../src/pages/404-not-found.tsx" /* webpackChunkName: "component---src-pages-404-not-found-tsx" */),
  "component---src-pages-investing-asset-class-tsx": () => import("./../../../src/pages/InvestingAssetClass.tsx" /* webpackChunkName: "component---src-pages-investing-asset-class-tsx" */),
  "component---src-pages-prospectus-tsx": () => import("./../../../src/pages/prospectus.tsx" /* webpackChunkName: "component---src-pages-prospectus-tsx" */),
  "component---src-templates-eqt-funds-fast-facts-page-tsx": () => import("./../../../src/templates/eqtFunds/FastFactsPage.tsx" /* webpackChunkName: "component---src-templates-eqt-funds-fast-facts-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/LandingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-people-page-tsx": () => import("./../../../src/templates/PeoplePage.tsx" /* webpackChunkName: "component---src-templates-people-page-tsx" */),
  "component---src-templates-person-tsx": () => import("./../../../src/templates/Person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */),
  "component---src-templates-press-release-page-tsx": () => import("./../../../src/templates/PressReleasePage.tsx" /* webpackChunkName: "component---src-templates-press-release-page-tsx" */),
  "component---src-templates-standard-page-tsx": () => import("./../../../src/templates/StandardPage.tsx" /* webpackChunkName: "component---src-templates-standard-page-tsx" */)
}

