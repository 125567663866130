import React, { useState } from 'react'
import '../../styles/Contact.css'
import contactlogo from '../../assets/icons/Contact-us-Logo/Contact-us-Logo.png'
import callicon from '../../assets/icons/Call Icon/Call Icon.png'
import messageicon from '../../assets/icons/Group 139/Group 139.png'

const Contactbutton: React.FC = () => {
  const [isContactDivVisible, setIsContactDivVisible] = useState(false);

  const toggleContactDiv = () => {
    setIsContactDivVisible(!isContactDivVisible);
  }
  const closeContactDiv = () => {
    setIsContactDivVisible(false)
  }
  return (
    <div>
      <button onClick={toggleContactDiv}>CONTACT US</button>
      {isContactDivVisible && (
        <div className='contact-modal'>
          <span className='text-orange-500 font-h2 cursor-pointer close-btn-cntc' onClick={closeContactDiv}>x</span>
          <img src={contactlogo} alt="logo" className='main-logo-cntc' />
          <h1>Contact</h1>
          <h4>Individual investors</h4>
          <p className='mb-30'> Please contact your financial advisor.</p>
          <h4 className='mb-10'>Stockholders</h4>
          <div className='flex items-center mobile-icon mb-10'>
            <img src={callicon} alt="logo" />
            <span>(833) 280-9523</span>
          </div>
          <h4 className='mb-10'>Financial professionals</h4>
          <div className='flex items-center  mobile-icon mb-10'>
            <img src={messageicon} alt="logo" />
            <span>pwm@eqtpartners.com</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default Contactbutton